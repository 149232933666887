
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import purchaseService from '@/services/purchase-services'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
@Component({
  components: {
    Header,
    SidebarPurchase,
    Loader,
    PopUp
  },
})
export default class PurchaseEnquires extends Vue {
  enquiry_no:any="";
  loading = false;
  public document_file: any = null;
  public payload: any = {}
  items: any = [];
  other_info:any=[]
  trans:any={};
  fieldType:any=""
  error:any="";
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
  cancel() {
    if(this.enquiry_no === undefined || this.enquiry_no === ""){
      this.error = 'Please enter enquiry number.'
      this.fieldType='cancel';
    }else if(this.payload.status === 'cancelled'){
      this.showModal('Enquiry is already cancelled.', [], "error");
    }else{
    this.payload['items'] = this.items
    purchaseService.cancelEnquiryRequest(this.payload.id).then((resp) => {
      this.showModal('Cancelled Successfully', [], "success");
      this.reset();
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  getEnquiry(enquiry_no: string) {
    if(this.enquiry_no === undefined || this.enquiry_no === ""){
      this.error = 'Please enter enquiry number.'
      this.fieldType='search'
    }else{
    purchaseService.getEnquiryRequest(enquiry_no).then((response) => {
      this.payload = response.data['purchase_enquiry']
      this.items = response.data['items']
      this.other_info = response.data['purchase_info']
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  reset(){
    this.payload = {};
    this.error="";
    this.items=[];
    this.enquiry_no="";
    this.other_info=[];
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
}
