import { render, staticRenderFns } from "./purchase-enquiries-cancellations.vue?vue&type=template&id=242f7719&scoped=true"
import script from "./purchase-enquiries-cancellations.vue?vue&type=script&lang=ts"
export * from "./purchase-enquiries-cancellations.vue?vue&type=script&lang=ts"
import style0 from "./purchase-enquiries-cancellations.vue?vue&type=style&index=0&id=242f7719&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242f7719",
  null
  
)

export default component.exports